// @use '../../../styles/scss/variables' as v;
@import '../../theme/mixins';

.container {
  float: right;
  padding: 30px 35px 0;
  background-color: #fff;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  min-height: calc(100vh - (6.875rem));
  box-shadow: #00000033 3px 3px 12px;
  flex-shrink: 0;
  @include md {
    max-width: 1100px;
  }
}

.header {
  width: 100%;
  height: 1.25rem;
  font-weight: 600;
  font-size: 1rem;
  display: flex;
  align-items: center;
}

.maxheight {
  height: 5rem;
}

.grid {
  position: relative;
  display: grid;
  width: 100%;
  grid-template-columns: 1fr;
  column-gap: 1rem;
  row-gap: 1rem;
  padding-bottom: 1rem;
  // margin-bottom: v.$fz-md;
  @include tablet-pt {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }
}

.phoneGrid {
  @extend .grid;
  @include tablet-pt {
    grid-template-columns: repeat(3, minmax(250px, 1fr));
  }
}

.address {
  // margin-bottom: v.$fz-md;
  padding-bottom: 1rem;
}

.gridRow {
  display: flex;
  column-gap: 1rem;
  width: 100%;
  &:first-child {
    margin-bottom: 1rem;
  }
  &:last-child {
    margin-top: 1rem;
    margin-bottom: 0;
  }
  & + & {
    margin-bottom: 1rem;
  }
}

.rightColumn {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.buttonGroup {
  display: flex;
  column-gap: 1rem;
  margin-bottom: 1rem;
}
