#registration-container {
  width: 100vw;
  height: 100vh;
}
#content {
  /* background-image: url('/login-background.jpg'); */
  /* background-repeat: no-repeat;
  background-size: 100vw;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  z-index: -1; */
  /* text-align: center; */
  /* vertical-align: center; */
}
#registration-form {
  width: 100%;
}

@media only screen and (max-width: 600px) {
  #registration-form {
    width: 100vw;
    height: 100%;
    margin: 0;
  }
  #content {
    height: 100vh;
    background-size: cover;
  }
}