.pillcontainer {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem;
}

.pill {
  display: flex;
  padding: 0.25rem 1rem;
  border-radius: 2.5rem;
  background-color: #5c5c5c;
}

.pilltext {
  font-weight: 400;
  color: #ffffff;
}
