.container{
  max-height: 1rem;
}

.outer{
  position: relative;
  margin: 1rem;
  width: 100%;
  border-radius: 10px;
  background-color:#e0e0de;
}

.inner {
  height: 100%;
  transition: width 2s;
  padding-left: 1rem;
  border-radius: 10px;
  min-height: 1rem;
  background-color:#70a84b;
  // #005d83 or rgba(0,93,131,255)
  background-color:#005d83;
  //background-color: #832600; // complementary color of: #005d83
  //background-color: #ff694e; // complementary color of: #4ee4ff;

  filter: brightness(1.4);   
    color: white;
  // background-image: linear-gradient(to top, #4ee4ff, #005d83 40%);
  box-shadow: 
    inset 0 2px 9px  rgba(255,255,255,0.3),
    inset 0 -2px 6px rgba(0,0,0,0.4);
  overflow: hidden;
}

.inner:after{
  min-height: 3rem;
  position: absolute;
  top: 0px; left: 0; bottom: 0; right: 0;
  background-image: linear-gradient(
    -45deg, 
    rgba(255, 255, 255, .2) 25%, 
    transparent 25%, 
    transparent 50%, 
    rgba(255, 255, 255, .2) 50%, 
    rgba(255, 255, 255, .2) 75%, 
    transparent 75%, 
    transparent
  );
  z-index: 1;
  background-size: 50px 50px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  overflow: hidden;
}