@use '@carbon/colors';
.container {
    & *:disabled {
        color: #8c8c8c; // $disabled-03
        -webkit-text-fill-color: #8c8c8c;
      }
    & :global(.bx--label--disabled), :global(.bx--label) {
        color: #171717;
        -webkit-text-fill-color: #171717;
    }
    & :global(input::placeholder), :global(textarea::placeholder), :global(.bx--form__helper-text--disabled) {
        color: #c6c6c6;
    }
}