// @import '../../styles/scss/mixins';

.container {
  position: relative;
  display: flex;
  flex-direction: row;
  padding: 5px;
  width: 100vw;
  min-height: 100%;
  height: fit-content;
  justify-content: center;
}

.contentContainer {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  padding: 5px;
  width: 100%;
}

.deniedContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: space-around;
  align-items: center;
}

.card {
  background-color: #ffffff;
  width: 50rem;
  padding: 1rem;
  max-width: 100%;
  min-height: 20rem;
}

.deniedHeader {
  font-size: 1.25rem;
}

.successHeader {
  font-size: clamp(1rem, 4vw, 1.35rem);
}

.successSubHeader {
  margin: 4rem 0;
  font-size: clamp(1rem, 4vw, 1.35rem);
}


.heading {
  font-size: clamp(1rem, 4vw, 1.35rem);
  margin-bottom: 10px;
  width: 100%;
  word-wrap: break-word;
  line-height: 27px;
  font-weight: bold;
  text-align: center;
}

.subHeading { 
  font-size: clamp(1rem, 4vw, 1.35rem);
  margin-bottom: 10px;
  width: 100%;
  word-wrap: break-word;
  line-height: 27px;
  text-align: center;
}

.label {
  margin-bottom: 1.25rem;
}

.text {
  align-self: flex-start;
  font-size: clamp(1rem, 4vw, 1.35rem);
  margin-bottom: 3rem;
  width: 100%;
  left: 20px;
  word-wrap: break-word;
  line-height: 27px;
}

.quizContent {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  align-self: center;
  align-items: center;
  max-width: 900px;
  width: 100%;
  height: 100%;
  margin-top: 1rem;
}

.checkinProgressBarContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 50rem;
  max-width: 100%;
  justify-content: space-between;
  @media screen and (max-width: 730px) {
    display: none;
  }
}

.ProgressTickContainer {
  display: flex;
  flex-direction: row;
  width: 80%;

  height: 8rem;
  align-items: center;
}

.checkinProgressBarTick {
  background-color: white;
  color: white;
  box-shadow: 1px 1px #888888;
  height: 3rem;
  width: 9rem;
  margin-right: -20px;
  text-align: center;
  line-height: 3rem;
  clip-path: polygon(0 0, 80% 0, 100% 50%, 80% 100%, 0 100%, 20% 50%);
  padding: 0rem 2rem;
  font-size: clamp(0.5rem, 4vw, 0.75rem);

  span {
    display: inline-block;
    vertical-align: middle;
    line-height: normal;
  }
}

.checkinProgressBarTickFilled {
  @extend .checkinProgressBarTick;
  background-color: rgba(0,145,179,1);
}


.npsButtons {
  max-width: 10rem;
  min-width: 8rem;
  text-align: center;
  justify-content: center;
  padding: 0rem 1rem;
  @media screen and (max-width: 730px) {
    max-width: 100%;
  }
}

.npsButtonsSelected {
  @extend .npsButtons;
  background-color: #33a7c2;
  color: #ffffff !important;
}

.quizInputWrapper {
  display: flex;
  width: 100%;
  justify-content: space-around;
  margin: 1.5rem 0rem;

  @media screen and (max-width: 730px) {
    flex-direction: column-reverse;
  }
}

.navButtonWrapper {
  display: flex;
  justify-content: left;
  
  button {
    margin: 0.5rem;
  }

  @media screen and (max-width: 425px) {
    justify-content: center;
    button {
      margin: 0;
      width: 50%;
    }
  }

}

.radioGroup {
  flex-direction: column;
  align-items: baseline;
}

.headerIcon {
  width: 7rem;

  @media screen and (max-width: 600px) {
    width: 100px;
    height: 100px;
  }
}

.backButtonIcon {
  margin-right: 0.5rem;
  height: 1rem;
}