.filterWrapper {
  display: flex;
  align-self: flex-start;
  flex-wrap: wrap;
}

.searchWrapper {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  margin-top: 0.87rem;
  width: 20rem;
  @media screen and (min-width: 500px) {
    margin-right: 0.5rem;
  }
  @media screen and (min-width: 900px) {
    margin-right: 1rem;
  }
  @media screen and (min-width: 1024px) {
    margin-right: 1rem;
  }
}

.filterButtonWrapper {
  align-self: flex-start;
  @media screen and (min-width: 500px) {
    margin-right: 0.5rem;
  }
  @media screen and (min-width: 900px) {
    margin-right: 1rem;
  }
  @media screen and (min-width: 1024px) {
    margin-right: 1rem;
  }
}

.rangePicker {
  width: 18rem;
  min-height: 3rem;
  z-index: 999;
  margin-top: 0.87rem;
}

.styledDropdown {
  width: auto;
  min-width: 15rem;
  min-height: 3rem;
  z-index: 999;
  margin-top: 0.87rem;
  .bx--list-box__menu {
    // min-width: 110%;
    width: fit-content;
    z-index: 10000;
  }
  @media screen and (max-width: 767px){
    width: 260px;
  }
}

.styledMultiSelectService {
  // width: auto;
  // min-width: 15rem;
  min-height: 3rem;
 
  // button {
  //   min-width: 10rem !important;
  //   // div {
  //   //   width: 10rem;
  //   //   min-width: 10rem;
  //   // }
  // }
  // #bx--multi-select {
  //   min-width: 20rem !important;
  // }
  // div:not(div.bx--tag__close-icon) {
  //   min-width: 20rem !important;
  // }
  // div:not(div.bx--tag.bx--tag--filter.bx--tag--high-contrast) {
  //   min-width: 20rem !important;
  // }
  // div:not(.bx--list-box__menu-icon) {
  //   min-width: 20rem !important;
  // }
  // div:not(.bx--list-box__menu-icon.bx--list-box__menu-icon--open) {
  //   min-width: 20rem !important;
  // }

    // min-width: 20rem !important;
    // .bx--tag__close-icon {
    //   min-width: auto;
    // }
    
    
    // .bx--list-box__menu {
    //   min-width: 20rem !important;
    // }
    
  
  // .bx--list-box__menu {
  //   min-width: 120%;
  //   // width: fit-content;
  //   z-index: 10000;
  // }
  // div #downshift-3-menu .bx--multi-select .bx--list-box__menu {
  //   min-width: 120%;
  //   color: red;
  // }
  // .bx--multi-select .bx--list-box__menu {
  //   min-width: 120% !important;
  //   color: red;
  // }
//   .bx--list-box--expanded .bx--list-box__menu {
//     width: 120%;
//     min-width: 20rem;
//     // width: fit-content;
//     z-index: 10000;
//   }
//   @media screen and (max-width: 767px){
//     width: 260px;
//   }
// }
}

.downloadButton {
  min-height: auto;
  padding: 1rem;
  height: 3rem;
  margin-top: 0.87rem;
  &:hover {
    background-color: red !important;
  }
}

.bookmarkButton {
  background-color: lightgrey !important;
  color: black !important;
  min-height: auto;
  padding: 1rem;
  height: 3rem;
  margin-top: 0.87rem;
  &:hover {
    background-color: #8eba71 !important;
  }
}

.flex {
  display: flex;
}