// base 16px

// 360px
@mixin mobile-s {
  @media (min-width: 22.5rem) {
    @content;
  }
}

// 375px
@mixin mobile-m {
  @media (min-width: 23.4375rem) {
    @content;
  }
}

// 414px
@mixin mobile-lg {
  @media (min-width: 25.875rem) {
    @content;
  }
}

// 600px
@mixin tablet-pt {
  @media (min-width: 37.5rem) {
    @content;
  }
}

// 768px
@mixin tablet-ls {
  @media (min-width: 48rem) {
    @content;
  }
}

// 992px
@mixin sm {
  @media (min-width: 62rem) {
    @content;
  }
}

// 1280px
@mixin md {
  @media (min-width: 80rem) {
    @content;
  }
}

// 1440px
@mixin lg {
  @media (min-width: 90rem) {
    @content;
  }
}

// 1800px
@mixin xl {
  @media (min-width: 112.5rem) {
    @content;
  }
}

// Without unit = px;
@mixin custom($screen) {
  @media (min-width: $screen) {
    @content;
  }
}

// Without unit = px;
@mixin custom-max($screen) {
  @media (max-width: $screen) {
    @content;
  }
}
