.container {
  width: 100%;
  display: flex;
  background-color: #4ee4ff;
  @media screen and (max-width: 750px) {
    flex-wrap: wrap;
  }
}

.bannerPrompt {
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  z-index: 800;
  text-align: center;
  .button,
  .text {
    font-size: 0.75rem;
    color: #000000;
  }

  .button {
    min-height: 1rem;
    margin: 0.5rem 16px;
    border: 1px solid #000000;
    padding: 0.3125rem 8px;
  }

  .clientName {
    padding-left: 0.5rem;
    padding-right: 1rem;
    font-weight: 600;
    font-size: 1rem;
  }
}
