.successMessage {
  margin: 1rem 1rem 0rem 1rem;
}

.textDiv {
  width: 75%;
  margin-left: 1rem;
  overflow: visible;
}

.modal {
  & .bx--modal-content {
    min-height: 20rem;
  }
}