// Screen Reader Only - for visually hidden elements
.srOnly {
	position: absolute !important;
	width: 1px !important;
	height: 1px !important;
	padding: 0 !important;
	margin: -1px !important;
	overflow: hidden !important;
	clip: rect(0,0,0,0) !important;
	white-space: nowrap !important;
	border: 0 !important;
}

@mixin sr-only ()
{
  .srOnly {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0,0,0,0) !important;
    white-space: nowrap !important;
    border: 0 !important;
  }
}

.pageContainer {
  // background-color: #d8d8d8;
  background-color: #fff;
  position: relative;
  color: black;
  width: 100%;
  height: auto;
  min-height: 100%;
  padding: 1rem 2rem;
  font-family: 'IBM Plex Sans';
  @media only screen and (min-width: 900px){
    padding: 1rem 4rem;
  }
}

// Apply grid layout to page in desktop mode
@media only screen and (min-width: 900px){
  .serviceInfoPage {
    display: grid;
    grid-template-columns: 660fr 421fr;
    grid-template-rows: 7rem repeat(5, 1fr);
    grid-column-gap: 160px;
    grid-row-gap: 0px; 
    .longDetailsSection {
      grid-area: 1 / 1 / 7 / 2;  
    }
    .shortDetailsSection {
      grid-area: 2 / 2 / 7 / 3;
    }
    .getSupportButtonWrapper { 
      grid-area: 1 / 2 / 2 / 3; 
    }
  }
}

.serviceInfoHeader {
  display: flex;
  margin-top: 1rem;
  .bookmarkButton {
    display: none;
    padding: 0 0.625rem;
    margin-top: .25rem;
    min-height: .5rem;
    .favorited {
      fill: #FF0000;
    }
    .notFavorited {
      fill: #000000;
    }
    @media only screen and (min-width: 900px){
      display:flex;
      align-items:first baseline;
    }
  }

  .connected {
    max-width: 2rem;
    svg {
      fill: #4c82ff;
    }
  }
  
  .serviceNamesAndConsent {
    margin-right: auto;
    h1 {
      font-size: 1.5rem; //24px
      font-weight: 600;
      padding-bottom: .5rem;
      svg {
        margin-left: .5rem;
      }
    }
    .orgName {
      font-size: 0.875rem; //14px
      padding-bottom: 1.125rem; //18px
      a {
        fill: #3467EC;
        text-decoration: none;
      }
      a:hover {
        text-decoration: underline;
      }
    }
  }
  .badgeWrapper {
    align-self: baseline;
    div {
     margin-top: 0;
    }
  }
  @media only screen and (min-width: 900px){
    margin-top: 2.25rem; //36px
  }
}

.serviceInfoNavBar {
  display: flex;
  justify-content: space-between;
  font-size: 1.125rem; // 18px
  text-decoration: none;
  border-top: 1px solid #BCBCBC;
  border-bottom:  1px solid #BCBCBC;
  a {
    color: #000000;
    text-decoration: none;
    padding: 1.25rem .5rem;
  }
  a:last-child {
    display: none;
  }
  a:hover {
    text-decoration:underline
  }
  @media only screen and (min-width: 900px){
    a:last-child {
    display:inline;
    }
  }
}
.getSupportButtonWrapper {   
  position: -webkit-sticky;
  position: sticky;
  background-color: white;
  z-index: 100;
  bottom: 0;
  width: 100vw;
  margin-left: -2rem;
  margin-bottom: 0;
  padding-bottom: .5rem;
  padding-right:2rem;
  padding-left:2rem;
  .getSupportButton {
    max-width: 100%;
    width: 100%;
  }
  & :global(.bx--label){
    @include sr-only()
  }
  @media only screen and (min-width: 900px){
    position:static;
    bottom: auto;
    margin-left: 0;
    padding: 2.6rem 0;
    width: 100%;
  }
}

.infoIcons {
  margin-right: 8px;
}

.styledHeader {
  margin-top: 0.5rem;
  margin-bottom: 1.675rem;
}

.infoFieldSet{
  position: relative;
  height: auto;
  width: 100%;
  padding: 1.5rem 0 0.625rem;
  font-size: 1.125rem; //18px
  .infoFieldContent {

  }
  li, div, p {
    font-family: 'IBM Plex Sans';
    font-size: 1.125rem; 
    line-height: 1.5;
    letter-spacing: default;
  }
}

.mapWrapper {
  margin-top: 2rem;
  @media screen and (min-width: 800px) {
    width: 100%;
  }
}
