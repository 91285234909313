@import "carbon-components/scss/globals/scss/styles.scss";
@import "carbon-components/scss/globals/scss/_vars.scss";
@import "@ibm/plex/css/ibm-plex.min.css";
@import "@carbon/grid/scss/grid";
$carbon--theme: $carbon--theme--g10;

* {
  font-family: "IBM Plex Sans";
}
body {
  margin: 0;
  background-color: #f4f4f4;
}

.bx--header__name {
  width: 16rem;
}
// .bx--header {
//   background-color: #072665;
// }
// @media (min-width: 66rem) {
.bx--content {
  // padding-left: 20rem;
  width: 100vw;
  min-width: 100%;
  // padding-right: 10rem;
  height: calc(100vh - 3rem);
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #f4f4f4;
  padding: 0;
  @media screen and (max-width: 767px) {
    height: calc(100vh - 3rem);
  }
}
// }

@media (min-width: 480px) {
  .bx--accordion__content {
    padding-right: 0px;
  }
}
.mapbox {
  height: calc(100vh - 3rem);
  margin-top: 3rem;
}
.bx--text-input:focus,
.bx--text-input:active {
  outline: none;
  outline-offset: none;
}
#notifications {
  position: fixed;
  right: 0;
  top: 5em;
}
@media only screen and (max-width: 600px) {
  #root > div {
    width: 100%;
    height: 100%;
  }

  .user-nav-button {
    display: none;
  }

  .bx--header ~ .bx--content {
    height: calc(100% - 3rem);
    padding: 0;
  }

  #root > div {
    display: flex;
    flex-direction: column;
  }

  .mapbox {
    height: 100%;
    margin: 0 !important;
  }

  #side-bar {
    display: none;
  }

  #bottom-nav {
    width: 100%;
  }

  #WACWidget .WACWidget .WACWidget--launched {
    max-height: 100% !important;
  }

  .mobile-nav-button {
    // margin: 0 1em;
    text-decoration: none;
    display: inline-block;
    // width: 20%;
  }

  a:link {
    text-decoration: none;
  }
}

#printable {
  display: none;
}
@media print {
  #printable {
    display: block;
  }
}

.ziptext {
  font-size: 0.75rem;
  letter-spacing: 0.32px;
  display: inline;
  margin-bottom: 0.5rem;
  color: #525252;
  font-weight: bold;
  line-height: 1rem;
  vertical-align: baseline;
  padding: 1.5rem;
}

@media screen and (max-width: 550px) and (min-width: 416px) {
  .qna {
    overflow: auto;
    align-items: center;
  }
}

@media screen and (max-width: 550px) and (min-width: 416px) {
  .qnasliders {
    overflow: auto;
    align-items: flex-start;
    justify-content: flex-start;
    align-content: stretch;
    flex-wrap: nowrap;
  }
  .bx--slider-container {
    margin-left: 1rem;
    margin-right: 2rem;
  }
  .bx--label {
    align-self: center;
  }
  .bx--slider {
    max-width: 20rem;
  }
  // .bx--btn-set {
  //   margin-left: 4rem;
  // }
  .hnp {
    margin-left: 1rem;
  }
}

@media (max-width: 416px) {
  .buttonContainer {
    margin-left: 25%;
    margin-right: 25%;
    margin-top: 5%;
    margin-bottom: 2%;
  }
  .bx--text-input {
    margin: auto;
    margin-bottom: 1em;
    margin-top: 1em;
  }
  // .bx--btn-set {
  //   margin-left: 0;
  // }
}

@media (max-width: 350px) {
  .bx--label {
    align-self: center;
  }

  .bx--slider-container {
    margin-left: 2rem;
    width: 300px;
    display: flex;

    overflow: auto;
  }

  .bx--slider {
    min-width: auto;
    max-width: 15rem;
    margin: auto;
  }

  .bx--form-item {
    width: 270px;
    align-items: center;
  }

  // .bx--btn-set {
  //   justify-content: center;
  // }
}

@media (max-width: 1540px) and (min-width: 1000px) {
  .bx--slider {
    max-width: 30rem;
  }
  // .bx--btn-set {
  //   margin-left: 2rem;
  //   justify-content: space-evenly;
  // }
}

@media (max-width: 300px) {
  .bx--label {
    align-self: center;
  }

  .bx--slider-container {
    margin-left: 1.5rem;
    width: 270px;
    display: flex;

    overflow: auto;
  }

  .bx--slider {
    min-width: auto;
    max-width: 15rem;
    margin: auto;
  }

  .bx--form-item {
    width: 250px;
    align-items: center;
  }

  // .bx--btn-set {
  //   justify-content: flex-end;
  // }
}

@media (max-width: 800px) and (min-width: 699px) {
  // .bx--btn-set {
  //   margin-left: 2rem;
  //   justify-content: flex-start;
  // }
  .hnp {
    margin-left: 2rem;
  }
}

@media screen and (max-width: 1000px) and (min-width: 900px) {
  .qnasliders {
    overflow: auto;
    align-items: flex-start;
    justify-content: flex-start;
    align-content: stretch;
    flex-wrap: nowrap;
  }
  .bx--slider-container {
    margin-left: 1rem;
    margin-right: 2rem;
  }
  .bx--label {
    align-self: center;
  }
  .bx--slider {
    max-width: 20rem;
  }
  // .bx--btn-set {
  //   margin-left: 4rem;
  // }
  .hnp {
    margin-left: 1rem;
  }
}

@media (max-width: 699px) and (min-width: 550px) {
  .qnasliders {
    overflow: auto;
    align-items: flex-start;
    justify-content: flex-start;
    align-content: stretch;
    flex-wrap: nowrap;
  }
  .bx--slider-container {
    margin-left: 1rem;
    margin-right: 2rem;
  }
  .bx--label {
    align-self: center;
  }
  .bx--slider {
    max-width: 20rem;
  }
  // .bx--btn-set {
  //   margin-left: 4rem;
  // }
  .hnp {
    margin-left: 1rem;
  }
}

@media (max-width: 470px) and (min-width: 417px) {
  .qnasliders {
    overflow: auto;
    align-items: flex-start;
    justify-content: flex-start;
    align-content: stretch;
    flex-wrap: nowrap;
  }
  .bx--slider-container {
    margin-left: 1rem;
    margin-right: 2rem;
  }
  .bx--label {
    align-self: center;
  }
  .bx--slider {
    max-width: 15rem;
  }
  // .bx--btn-set {
  //   margin-left: 4rem;
  // }
  .hnp {
    margin-left: 1rem;
  }
}

// @media (min-width: 1540px) {
//   .bx--btn-set {
//     margin-left: 2rem;
//   }
// }

// Screen Reader Only - for visually hidden elements
.srOnly {
	position: absolute !important;
	width: 1px !important;
	height: 1px !important;
	padding: 0 !important;
	margin: -1px !important;
	overflow: hidden !important;
	clip: rect(0,0,0,0) !important;
	white-space: nowrap !important;
	border: 0 !important;
}
