// @use '../../../../styles/scss/variables' as v;

.container {
  margin: auto;
  background-color: white;

  @media (max-width: 900px) {
    width: 95%;
    flex-direction: column;
    border-bottom: 0.25rem solid rgba(0,93,131,1);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  }

  display: flex;
  flex-direction: row;
  margin-top: 0.75rem;
  align-items: space-between;
  height: auto;
  min-height: 150px;
  width: 100%;
  padding: 1rem 1rem 0.5rem 1rem;
  border: 1.8px solid lightgray;
  border-radius: 0;
  box-shadow: none;
  z-index: 1;
  &:first-of-type {
    margin-top: 0;
  }

  @media screen and (max-width: 769px) {
    width: 100%;
    min-height: auto;
    padding-bottom: 1rem;
  }

}

.container:hover {
  background-color: aliceblue;
}

.cardSpacing {
  @media (max-width: 900px) {
    padding: 0.5rem;
  }
}

.mobileFullWidth {
  @media (max-width: 900px) {
    width: 100% !important;
  }
}

.sectionPadding {
  padding: 0 0.5rem 0 0;
  @media screen and (max-width: 769px) {
    padding: 0;
  }
}

.left {
  width: 30%;
  @extend .mobileFullWidth;
  @extend .sectionPadding;

  @media (max-width: 900px) {
    display: grid;
    grid-template-columns: 2fr 1fr;
  }

  @media (max-width: 425px) {
    grid-template-columns: 1fr 1fr;
  }
}
.right {
  width: 30%;
  justify-content: space-evenly;
  @media (max-width: 900px) {
    display: none;
  }
}

.middle {
  width: 40%;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  @extend .mobileFullWidth;
  @extend .sectionPadding;

  @media screen and (max-width: 900px) {
    border-top: 1px solid black;
    margin-top: 1rem;
    padding-top: 1rem;
  }
}

.header {
  width: 100%;
  min-height: 2.5rem;
  font-weight: 600;
  display: flex;
  flex-direction: column;

  p {
    font-weight: 600;
  }
}

.serviceNameConnectedContainer {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.serviceName {
  font-size: 0.875rem !important;
}

.org {
  width: 100%;
  font-weight: 400;
  font-size: 0.75rem;
  color: #2764f5;
  text-decoration: none;
  overflow-wrap: break-word;
}

.org:hover {
  text-decoration: underline;
}

.subheader {
  width: 100%;
  font-weight: 600;
  font-size: 0.875rem;
  margin-bottom: 0.5rem;

  @media screen and (max-width: 900px) {
    display: none;
  }
}

.description {
  line-height: 1.5;
  overflow-wrap: break-word;
  font-size: 0.75rem !important;
}

.viewMoreContainer {
  margin: 0.5rem 0;
}

.viewMore {
  @extend .org;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  font-size: 0.875rem;
  width: 100%;
  
  background: none!important;
  border: none;
  padding: 0!important;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }

  @media screen and (min-width: 769px) {
    font-size: 0.75rem;
    font-weight: 400;
    width: fit-content;
    svg {
      display: none;
    }
  }
}

.paragraph {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.paragraphContainer {
  font-weight: 400;
  font-size: 0.7rem;
  display: flex;
  margin-top: 0.5rem;
  margin-left: 1.25rem;
}

.content {
  width: 100%;
  font-weight: 400;
  font-size: 0.75rem !important;
  margin-top: 0.25rem;
  display: flex;
  align-items: center;
  color: #7d7d7d;
}

a.content {
  color: #2764f5;
  text-decoration: none;
}

a.content:hover {
  text-decoration: underline;
}

.favorite {
  max-width: 3rem;
  cursor: pointer;
  margin-right: 0.5rem;
  color: red;
  :global(.dark) & {
    color: rgb(255, 90, 90);
  }
  :global(.light) & {
    color: red;
  }
}

.favoriteDesktopContainer {
  width: 1.5rem;
  @media (max-width: 900px) {
    display: none;
  }
}

.favoriteMobileContainer {
  @media (min-width: 901px) {
    display: none;
  }
}

.dropdownMobileContainer {
  @media (min-width: 901px) {
    display: none;
  }
}

.connected {
  max-width: 2rem;
  margin: 0rem 0.5rem;
  svg {
    fill: #4c82ff;
  }
}


.languageBadge {
  font-weight: 400;
  font-size: 0.7rem;
  padding: 0.25rem 0.75rem;
  border: 1px solid #8e4edb;
  margin-left: 0.75rem;
  background-color: #bea4fa;
  color: white;
}

.viewMore {
  color: #2764f5;
  cursor: pointer;
  padding-top: 0.25rem;
  :global(.dark) & {
    color: #cbd1df;
  }
  :global(.light) & {
    color: #2764f5;
  }
}

.addToCallLogWrapper {
  display: flex;
  justify-content: flex-end;
}

.addToCallLogButton {
  padding: 3px;
  min-height: 3rem;
  width: 3rem;
  height: 3rem;
  justify-content: center;
}
