// Overriding ES styles
.sui-layout-body__inner {
  max-width: 100% !important;
}

:global(#sui-results-page-page-options-dropdown) {
  height: 2rem;
  border-bottom: none;
  border-radius: 5px;
}
// End of ES styles

// Custom styling
.wrapper {
  height: auto;
  min-height: 100%;
}

.wrapper div {
  text-align: left !important;
}

.bodyContainer {
  background-color: #ffffff;
  position: relative;
  height: calc(100vh - 3rem);
  @media (min-width: 768px) {
    display: flex;
    flex-direction: column;
    display: grid;
  }
  & p {
    @media (max-width: 1280px) {
      font-size: 16px;
    }
    @media (min-width: 1280px) and (max-width: 1600px) {
      font-size: 13px;
    }
    @media (min-width: 1600px) {
      font-size: 15px;
    }
  }
}

.sidebarContainer {
  position: absolute;
  background-color: #fff;
  z-index: 3;
  height: 100%;
  width: 100%;
  padding: 1rem;
  overflow-y: auto;
  @media screen and (min-width: 768px) {
    position: relative;
    border: 1px solid rgba(0, 0, 0, 0.25);
    box-shadow: 2px 0px 2px rgba(0, 0, 0, 0.1), 3px 0px 5px rgba(0, 0, 0, 0.05);
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-height: inherit;
    overflow-y: auto;
    animation: fadein .2s ease-out;
  }
}

.facet {
  margin-top: 0rem !important;
  width: 100%;
}

.contentContainer {
  position: relative;
  height: 100%;
  width: 100%;
  z-index: 2;
}

.providersContainer {
  position: relative;
  height: 100%;
  @media (min-width: 1024px) {
    display: grid;
    grid-template-columns: 2fr 1fr;
  }

  @media (min-width: 1600px) {
    grid-template-columns: 1.75fr 1.25fr;
  }
}

.header {
  background-color: #ffffff;
  z-index: 2;
  position: relative;
  padding: 0 0.75rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  @media (min-width: 540px) {
    height: 3rem;
    margin: 1rem 0 2rem;
    flex-direction: row;
  }
}

.searchAndResultsContainer {
  position: relative;
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-rows: 10rem 1fr;

  @media (min-width: 540px) {
    grid-template-rows: 5rem 1fr;
  }
}

.mapContainer {
  position: absolute;
  z-index: 3;
  top: 11.125rem;
  width: 100%;
  height: calc(100% - 11.125rem);
  @media screen and (min-width: 768px) {
    top: 8.125rem;
    height: calc(100% - 8.125rem);
  }

  @media screen and (min-width: 1024px) {
    position: relative;
    height: 100%;
    top: 0;
  }

  @media screen and (max-width: 1023px) {
    display: none;
  }
}

.resultsContainer {
  position: relative;
  z-index: 1;
  height: calc(90vh - 9rem);
  @media (min-width: 2560px) {
    height: calc(95vh - 2.25rem);
  }
  @media (min-width: 540px) {
    height: calc(95vh - 6rem);
  }
}

.leftColumnHeader {
  top: -1px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem;
}

.filterWrapper {
  grid-area: "Filters";
  display: flex;
  flex-direction: column;
  z-index: 4;
}

.leftColumnFooter {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 1rem 0rem;
  ul {
    font-size: 1rem !important;
  }
}

.resultsScrollContainer {
  height: calc(100% - 7rem);
  padding-top: 2px;
  overflow-y: auto;
  z-index: 2;
  @media screen and (min-width: 768px) {
  }
}

.resultsInnerContainer {
  width: 100%;
  padding-right: 0.25rem;
}

.loadingContainer {
  position: fixed;
  z-index: 6000;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

// Map Tooltips
.popupContent {
  display: flex;
  flex-direction: column;
}

.popupTopRow {
  display: flex;
  justify-content: space-between;
}

.popupTextWrapper {
  height: fit-content;
  display: flex;
  flex-direction: column;

  h4 {
    font-weight: 600;
    font-size: 0.875rem !important;
  }
  a {

    width: 100%;
    font-weight: 400;
    font-size: 0.75rem;
    color: #2764f5;
    text-decoration: none;
    overflow-wrap: break-word;
  }

  a:hover {
    text-decoration: underline;
  }

  svg {
    fill: #4c82ff;
    margin: 0rem 5px;
    width: 2rem;
  }
}

.popupBottomRow {
  @extend .popupTopRow;
  flex-direction: column;
}

.popupAddressWrapper {
  width: 100%;
  height: fit-content;
  p {
    color: #7d7d7d;
    width: 100%;
    font-weight: 400;
    font-size: 0.75rem !important;
    margin-top: 0.25rem;
    display: flex;
    align-items: center;
  }
}

.popupViewMore {
  align-self: flex-start;
  margin-top: 0.5rem;
  width: 100%;
  overflow-wrap: break-word;

  a {
    font-weight: 400;
    font-size: 0.75rem;
    color: #2764f5;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }
}

.pagingInfo {
  .searchTerm {
    font-style: italic;
  }
}

// End Map Tooltips

// End of custom styling